import React from 'react';

export default function ApplePayComponent() {
    const paymentMethods = [
        {
            supportedMethods: "https://apple.com/apple-pay",
            data: {
                version: 3,
                merchantIdentifier: "merchant.com.mk.qctlc",
                merchantCapabilities: ["supports3DS"],
                supportedNetworks: ["amex", "discover", "masterCard", "visa"],
                countryCode: "US",
            },
        },
    ];
    const options = {
        requestPayerName: false,
        requestBillingAddress: false,
        requestPayerEmail: false,
        requestPayerPhone: false,
        requestShipping: false,
        shippingType: "shipping",
    };
    const details = {
        total: {
            label: "Demo (Card is not charged)",
            amount: { value: "27.50", currency: "USD" },
        },
        displayItems: [
            {
                label: "Tax",
                amount: { value: "2.50", currency: "USD" },
            },
            {
                label: "Ground Shipping",
                amount: { value: "5.00", currency: "USD" },
            },
        ],
        shippingOptions: [
            {
                id: "ground",
                label: "Ground Shipping",
                amount: { value: "5.00", currency: "USD" },
                selected: true,
            },
            {
                id: "express",
                label: "Express Shipping",
                amount: { value: "10.00", currency: "USD" },
            },
        ],
    };
    const handleApplePay = async () => {
        if (!window.PaymentRequest) {
            console.error("Payment Request API not supported");
            return;
        }
        const request = new PaymentRequest(paymentMethods, details, options);
        request.onpaymentmethodchange = (event) => {
            const paymentDetailsUpdate = {
                total: {
                    label: "Demo (Card is not charged)",
                    amount: { value: "27.50", currency: "USD" },
                },
            };
            event.updateWith(paymentDetailsUpdate);
        };
        // request.onshippingoptionchange = (event) => {
        //     const paymentDetailsUpdate = {
        //         total: {
        //             label: "Demo (Card is not charged)",
        //             amount: { value: "27.50", currency: "USD" },
        //         },
        //     };
        //     event.updateWith(paymentDetailsUpdate);
        // };
        // request.onshippingaddresschange = (event) => {
        //     const paymentDetailsUpdate = {
        //         total: {
        //             label: "Demo (Card is not charged)",
        //             amount: { value: "27.50", currency: "USD" },
        //         },
        //     };
        //     event.updateWith(paymentDetailsUpdate);
        // };

        try {
            const response = await request.show();
            console.log("Payment response: ", response);
            await response.complete("success");
        } catch (error) {
            console.error("Payment Request error:", error);
        }
    };

    return (
        <div
        className='applePe'
            onClick={(e) => {
                e.preventDefault();
                handleApplePay();
            }}
        >
            <button className='applepaybutton'>Buy with ApplePay</button>
            {/* <apple-pay-button buttonstyle="white-outline" type="book" locale="en-US"></apple-pay-button> */}
        </div>
    );
}
