import React, { useEffect } from "react";
import "./OrderSummary.scss";
import { images } from "../../../actions/customFn";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { errorMsg } from "../../../services/customeFn";
import StripeElement from "../CheckOutForm/StripeElement";
import ApplePayButton from "../CheckOutForm/___ApplePayButton";
import GooglePay from "../CheckOutForm/GooglePay";
import ApplePayComponent from "../CheckOutForm/ApplePay";

const OrderSummary = ({
  buyPassDetails,
  purchaseBuypassData,
  onSuccessfulPayment,
  paymentMethod,
  errors,
  formdata,
  validateFormData,
  handleCheckout,
  setFormdata
}) => {
  const initialOptions = {
    "client-id":
      "Af1wQrdr-PflnG5z6lMBNzlpPFev7jpcyDL5K7WRvA4F9x3yHctuYQ8FISkN0BAWfs7Buks6D72K1Hh2",
  };

  useEffect(() => {
    validateFormData();
  }, [paymentMethod]);

  return (
    <div className="ordersummary_mainsection">
      <h4>Order Summary</h4>

      <div className="order_detailsummery">
        <div className="product_details">
          <div className="product_infoflex">
            <img src={images["product1.png"]} alt="" />
            <div className="right_content">
              <h6>{buyPassDetails?.title}</h6>
              <p>{buyPassDetails?.description}</p>
              <h3>${buyPassDetails?.price}</h3>
            </div>
          </div>

          <div className="info_flexdiv">
            <p>Start date </p>
            <p>
              <span>{purchaseBuypassData?.start_date}</span>
            </p>
          </div>
          <div className="info_flexdiv">
            <p>End date </p>
            <p>
              <span>{buyPassDetails?.end_date}</span>
            </p>
          </div>
          <div className="info_flexdiv">
            <p>Number of people</p>
            <p>
              <span>{purchaseBuypassData?.quantity}</span>
            </p>
          </div>
        </div>

        <div className="discount_totalbottom">
          <div className="info_flexdiv">
            <h6>Total </h6>
            <h6>
              <span>
                ${buyPassDetails?.price * purchaseBuypassData?.quantity}
              </span>
            </h6>
          </div>
          {console.log("paymentMethod", paymentMethod)}

          {errors &&
          Object.keys(errors).length == 0 &&
          paymentMethod === "paypal" ? (
            <div className="paypal_button_container">
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  fundingSource="paypal"
                  style={{
                    layout: "vertical", // or 'horizontal'
                    shape: "pill", // options: 'rect', 'pill'
                    label: "checkout", // options: 'paypal', 'checkout', 'buynow', 'pay'
                  }}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value:
                              buyPassDetails.price *
                              purchaseBuypassData?.quantity,
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={(data, actions) => {
                    return actions.order
                      .capture()
                      .then((details) => {
                        onSuccessfulPayment(details, formdata);
                      })
                      .catch((err) => {
                        errorMsg(err);
                        // console.error("Payment capture failed:", err);
                        // Handle payment capture error (e.g., show error message to user)
                      });
                  }}
                  onCancel={() => {
                    // errorMsg("Payment was cancelled by the user.");
                    // Handle payment cancellation (e.g., notify the user)
                  }}
                  onError={(err) => {
                    if (err.message !== "Detected popup close") {
                      errorMsg(
                        err.message || "An error occurred during payment."
                      );
                    }
                  }}
                />
              </PayPalScriptProvider>
            </div>
          ) : paymentMethod === "stripe" ? (
            <>
              <StripeElement 
              setFormdata={setFormdata}
              handleCheckout={handleCheckout}
              />
            </>
          ) : paymentMethod === "apple_pay" ? (
            <>
              <ApplePayComponent/>
            </>
          ) : paymentMethod === "google_pay" ? (
            <>
              <div className="">
                <GooglePay />
              </div>
            </>
          ) : (
            <>
              <button
                type="button"
                className="checkout_btn"
                onClick={handleCheckout()}
              >
                Checkout
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
