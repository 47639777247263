import React, { useEffect, useState } from "react";
import { images } from "../../../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import "./header.scss";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import LoginModal from "../../Modals/LoginModal/LoginModal";
// import { LoginModal } from "../..";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Redux/Slice/authSlice";
import { useLocation } from "react-router-dom";
import { successMsg } from "../../../services/customeFn";
import { useGetPageDataMutation } from "../../../Redux/RTKQ/pageApiSlice";

const Header = () => {
  const { isAuthenticated, customerDetails, settingData } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const [loginmodal, setLoginModal] = useState(false);

  const closeLoginModal = () => {
    setLoginModal(false);
  };

  const openLoginModal = () => {
    setLoginModal(true);
  };

  const handleLogout = () => {
    successMsg("Logout successfully");
    dispatch(logout());
    navigate("/");
  };

  return (
    <>
      <div className="header-main-section">
        <div className="container">
          <div className="header-grid-main">
            <div className="logo-main">
              <NavLink to="/">
                <img src={settingData?.header_logo} alt="logo" />
              </NavLink>
            </div>
            <div className="header-all-menu">
              <ul className="hide_inresponsive">
                <li>
                  <NavLink to="/"> Home</NavLink>
                </li>
                <li>
                  <NavLink to={"/contact-us"}>Contact Us</NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/buy-passes"}
                    // className={({ isActive }) =>
                    //   to !== "" && isActive ? "active" : ""
                    // }
                  >
                    Buy Passes
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/promotion"}
                    // className={({ isActive }) =>
                    //   to !== "" && isActive ? "active" : ""
                    // }
                  >
                    Promotion
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/qct-history"}
                    // className={({ isActive }) =>
                    //   to !== "" && isActive ? "active" : ""
                    // }
                  >
                    QCT History
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/track-enhancements"}
                    // className={({ isActive }) =>
                    //   to !== "" && isActive ? "active" : ""
                    // }
                  >
                    {" "}
                    Track Enhancements
                  </NavLink>
                </li>
                <li>
                  {isAuthenticated ? (
                    <Dropdown className="header_profilebtn">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="btn-drop-user login_button "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 5.99988C11.2583 5.99988 10.5333 6.21981 9.91661 6.63187C9.29993 7.04392 8.81928 7.62959 8.53545 8.31482C8.25162 9.00004 8.17736 9.75404 8.32206 10.4815C8.46675 11.2089 8.8239 11.8771 9.34835 12.4015C9.8728 12.926 10.541 13.2831 11.2684 13.4278C11.9958 13.5725 12.7498 13.4983 13.4351 13.2144C14.1203 12.9306 14.706 12.45 15.118 11.8333C15.5301 11.2166 15.75 10.4916 15.75 9.74988C15.75 8.75532 15.3549 7.80149 14.6517 7.09823C13.9484 6.39497 12.9946 5.99988 12 5.99988ZM12 11.9999C11.555 11.9999 11.12 11.8679 10.75 11.6207C10.38 11.3735 10.0916 11.022 9.92127 10.6109C9.75098 10.1998 9.70642 9.74738 9.79324 9.31093C9.88005 8.87447 10.0943 8.47356 10.409 8.15889C10.7237 7.84422 11.1246 7.62993 11.561 7.54311C11.9975 7.45629 12.4499 7.50085 12.861 7.67115C13.2722 7.84145 13.6236 8.12983 13.8708 8.49985C14.118 8.86986 14.25 9.30487 14.25 9.74988C14.2493 10.3464 14.0121 10.9183 13.5902 11.3401C13.1684 11.7619 12.5965 11.9992 12 11.9999Z"
                            fill="white"
                          />
                          <path
                            d="M12 1.50012C9.9233 1.50012 7.89323 2.11594 6.16652 3.26969C4.4398 4.42345 3.09399 6.06332 2.29927 7.98195C1.50455 9.90057 1.29661 12.0118 1.70176 14.0486C2.1069 16.0854 3.10693 17.9563 4.57538 19.4247C6.04384 20.8932 7.91476 21.8932 9.95156 22.2984C11.9884 22.7035 14.0996 22.4956 16.0182 21.7009C17.9368 20.9061 19.5767 19.5603 20.7304 17.8336C21.8842 16.1069 22.5 14.0768 22.5 12.0001C22.4969 9.21631 21.3896 6.54742 19.4212 4.57897C17.4527 2.61051 14.7838 1.50326 12 1.50012ZM7.50001 19.7825V18.7501C7.50066 18.1536 7.73792 17.5817 8.15974 17.1599C8.58155 16.738 9.15347 16.5008 9.75001 16.5001H14.25C14.8465 16.5008 15.4185 16.738 15.8403 17.1599C16.2621 17.5817 16.4994 18.1536 16.5 18.7501V19.7825C15.1344 20.5799 13.5814 21.0001 12 21.0001C10.4186 21.0001 8.86563 20.5799 7.50001 19.7825ZM17.9944 18.6945C17.9795 17.7107 17.5786 16.7721 16.8782 16.081C16.1778 15.39 15.2339 15.0018 14.25 15.0001H9.75001C8.76608 15.0018 7.8222 15.39 7.1218 16.081C6.42139 16.7721 6.02054 17.7107 6.00563 18.6945C4.64555 17.48 3.68643 15.8811 3.25526 14.1095C2.82408 12.3378 2.9412 10.477 3.59111 8.77337C4.24101 7.06975 5.39304 5.60372 6.89466 4.5694C8.39627 3.53507 10.1766 2.98124 12 2.98124C13.8234 2.98124 15.6037 3.53507 17.1054 4.5694C18.607 5.60372 19.759 7.06975 20.4089 8.77337C21.0588 10.477 21.1759 12.3378 20.7448 14.1095C20.3136 15.8811 19.3545 17.48 17.9944 18.6945Z"
                            fill="white"
                          />
                        </svg>{" "}
                        {/* {customerDetails?.full_name} */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <h6 className="username_to_dropdownmenu">
                          {customerDetails?.full_name}
                        </h6>

                        <Dropdown.Item>
                          <NavLink to="/dashboard">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path d="M3 13H11V21H3V13Z" fill="#505050" />
                                <path d="M13 3H21V10H13V3Z" fill="#505050" />
                                <path d="M13 13H21V21H13V13Z" fill="#505050" />
                                <path d="M3 3H11V10H3V3Z" fill="#505050" />
                              </svg>
                            </span>
                            Dashbaord
                          </NavLink>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <NavLink to={"/edit-profile"}>
                            <span className="sidebar-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="18"
                                viewBox="0 0 16 18"
                                fill="none"
                              >
                                <path
                                  d="M8 0C6.97336 0 5.98876 0.431548 5.26281 1.19971C4.53687 1.96787 4.12903 3.00972 4.12903 4.09606C4.12903 5.1824 4.53687 6.22425 5.26281 6.99241C5.98876 7.76057 6.97336 8.19212 8 8.19212C9.02664 8.19212 10.0112 7.76057 10.7372 6.99241C11.4631 6.22425 11.871 5.1824 11.871 4.09606C11.871 3.00972 11.4631 1.96787 10.7372 1.19971C10.0112 0.431548 9.02664 0 8 0ZM3.87097 10.3767C2.84432 10.3767 1.85973 10.8082 1.13378 11.5764C0.407833 12.3446 0 13.3864 0 14.4727V15.7704C0 16.594 0.563613 17.2952 1.33161 17.4274C5.74761 18.1909 10.2524 18.1909 14.6684 17.4274C15.0401 17.3633 15.3781 17.1615 15.6221 16.8579C15.866 16.5544 16 16.1689 16 15.7704V14.4727C16 13.3864 15.5922 12.3446 14.8662 11.5764C14.1403 10.8082 13.1557 10.3767 12.129 10.3767H11.7781C11.5871 10.3767 11.3972 10.4095 11.2165 10.4706L10.3226 10.7797C8.81338 11.301 7.18662 11.301 5.67742 10.7797L4.78348 10.4706C4.60248 10.4083 4.41331 10.3766 4.22297 10.3767H3.87097Z"
                                  fill="#505050"
                                />
                              </svg>
                            </span>
                            My Profile
                          </NavLink>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <NavLink to={"/coupon"}>
                            <span className="sidebar-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="15"
                                viewBox="0 0 21 15"
                                fill="none"
                              >
                                <path
                                  d="M19.0847 0H18.0802H16.0713H4.01783H2.00891H1.00446C0.448992 0 0 0.448992 0 1.00446V2.00891V3.95555V5.02228H0.89698C1.89742 5.02228 2.82554 5.70632 2.98625 6.6937C3.19116 7.95228 2.22487 9.04011 1.00446 9.04011H0V10.1068V12.0535V13.0579C0 13.6134 0.448992 14.0624 1.00446 14.0624H2.00891H4.01783H16.0713H18.0802H19.0847C19.6401 14.0624 20.0891 13.6134 20.0891 13.0579V12.0535V10.1068V9.04011C20.0891 9.04011 19.6401 9.04011 19.0847 9.04011C17.8643 9.04011 16.898 7.95228 17.1029 6.6937C17.2636 5.70632 18.1917 5.02228 19.1922 5.02228H20.0891V3.95555V2.00891V1.00446C20.0891 0.448992 19.6401 0 19.0847 0ZM9.04011 12.0535H7.0312V10.0446H9.04011V12.0535ZM9.04011 8.03566H7.0312V6.02674H9.04011V8.03566ZM9.04011 4.01783H7.0312V2.00891H9.04011V4.01783Z"
                                  fill="#505050"
                                ></path>
                              </svg>
                            </span>
                            Coupons
                          </NavLink>
                        </Dropdown.Item>

                        {customerDetails?.type == "Vendor" && (
                          <>
                            <Dropdown.Item>
                              <NavLink to={"/buypass/add"}>
                                <span
                                  className="sidebar-icon"
                                  style={{ marginLeft: "-3px" }}
                                >
                                  <svg
                                    class="w-6 h-6 text-gray-800 dark:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#505050"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M12 14a3 3 0 0 1 3-3h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a3 3 0 0 1-3-3Zm3-1a1 1 0 1 0 0 2h4v-2h-4Z"
                                      clip-rule="evenodd"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      d="M12.293 3.293a1 1 0 0 1 1.414 0L16.414 6h-2.828l-1.293-1.293a1 1 0 0 1 0-1.414ZM12.414 6 9.707 3.293a1 1 0 0 0-1.414 0L5.586 6h6.828ZM4.586 7l-.056.055A2 2 0 0 0 3 9v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2h-4a5 5 0 0 1 0-10h4a2 2 0 0 0-1.53-1.945L17.414 7H4.586Z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </span>
                                Buy Pass
                              </NavLink>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <NavLink to={"/invoices"}>
                                <span
                                  className="sidebar-icon"
                                  style={{ marginLeft: "-3px" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                  >
                                    <path
                                      d="M19 3h-2V1H7v2H5a2 2 0 00-2 2v16a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zM7 3h10v2H7V3zm12 16H5V7h14v12zM7 9h6v2H7V9zm0 4h6v2H7v-2zm8 2h2v2h-2v-2z"
                                      fill="#505050"
                                    />
                                  </svg>
                                </span>
                                Invoices
                              </NavLink>
                            </Dropdown.Item>
                          </>
                        )}

                        {customerDetails?.type == "Websitevendor" && (
                          <Dropdown.Item>
                            <NavLink to="/api">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M16 5.5C16 8.53757 13.5376 11 10.5 11H7V13H5V15L4 16H0V12L5.16351 6.83649C5.0567 6.40863 5 5.96094 5 5.5C5 2.46243 7.46243 0 10.5 0C13.5376 0 16 2.46243 16 5.5ZM13 4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4Z"
                                    fill="#505050"
                                  />
                                </svg>
                              </span>
                              Api
                            </NavLink>
                          </Dropdown.Item>
                        )}

                        {customerDetails?.type != "Websitevendor" && (
                          <Dropdown.Item>
                            <NavLink to={"/viewpass-list"}>
                              <span className="sidebar-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="17"
                                  viewBox="0 0 21 17"
                                  fill="none"
                                >
                                  <path
                                    d="M19.3201 0.653809H2.52076C1.59329 0.653809 0.84082 1.40628 0.84082 2.33375V14.6533C0.84082 15.5807 1.59329 16.3332 2.52076 16.3332H19.3201C20.2476 16.3332 21.0001 15.5807 21.0001 14.6533V2.33375C21.0001 1.40628 20.2476 0.653809 19.3201 0.653809ZM7.00059 4.01368C8.23604 4.01368 9.24051 5.01814 9.24051 6.2536C9.24051 7.48905 8.23604 8.49351 7.00059 8.49351C5.76514 8.49351 4.76067 7.48905 4.76067 6.2536C4.76067 5.01814 5.76514 4.01368 7.00059 4.01368ZM10.9204 12.3014C10.9204 12.6724 10.5705 12.9733 10.1365 12.9733H3.86471C3.43072 12.9733 3.08074 12.6724 3.08074 12.3014V11.6294C3.08074 10.5164 4.1342 9.61347 5.43265 9.61347H5.60764C6.03813 9.79196 6.50711 9.89346 7.00059 9.89346C7.49407 9.89346 7.96655 9.79196 8.39354 9.61347H8.56853C9.86698 9.61347 10.9204 10.5164 10.9204 11.6294V12.3014ZM18.7601 10.4534C18.7601 10.6074 18.6342 10.7334 18.4802 10.7334H13.4403C13.2864 10.7334 13.1604 10.6074 13.1604 10.4534V9.89346C13.1604 9.73947 13.2864 9.61347 13.4403 9.61347H18.4802C18.6342 9.61347 18.7601 9.73947 18.7601 9.89346V10.4534ZM18.7601 8.21352C18.7601 8.36752 18.6342 8.49351 18.4802 8.49351H13.4403C13.2864 8.49351 13.1604 8.36752 13.1604 8.21352V7.65354C13.1604 7.49955 13.2864 7.37356 13.4403 7.37356H18.4802C18.6342 7.37356 18.7601 7.49955 18.7601 7.65354V8.21352ZM18.7601 5.97361C18.7601 6.1276 18.6342 6.2536 18.4802 6.2536H13.4403C13.2864 6.2536 13.1604 6.1276 13.1604 5.97361V5.41363C13.1604 5.25963 13.2864 5.13364 13.4403 5.13364H18.4802C18.6342 5.13364 18.7601 5.25963 18.7601 5.41363V5.97361Z"
                                    fill="#505050"
                                  ></path>
                                </svg>
                              </span>
                              Purchase History
                            </NavLink>
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item>
                          <NavLink to={"/change-password"}>
                            <span className="sidebar-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="19"
                                viewBox="0 0 16 19"
                                fill="none"
                              >
                                <path
                                  d="M13.3333 6.22222H15.1111C15.3469 6.22222 15.573 6.31587 15.7397 6.48257C15.9064 6.64927 16 6.87536 16 7.11111V17.7778C16 18.0135 15.9064 18.2396 15.7397 18.4063C15.573 18.573 15.3469 18.6667 15.1111 18.6667H0.888889C0.653141 18.6667 0.427048 18.573 0.260349 18.4063C0.0936505 18.2396 0 18.0135 0 17.7778V7.11111C0 6.87536 0.0936505 6.64927 0.260349 6.48257C0.427048 6.31587 0.653141 6.22222 0.888889 6.22222H2.66667V5.33333C2.66667 3.91885 3.22857 2.56229 4.22876 1.5621C5.22896 0.561903 6.58551 0 8 0C9.41449 0 10.771 0.561903 11.7712 1.5621C12.7714 2.56229 13.3333 3.91885 13.3333 5.33333V6.22222ZM11.5556 6.22222V5.33333C11.5556 4.39034 11.181 3.48597 10.5142 2.81918C9.84736 2.15238 8.94299 1.77778 8 1.77778C7.05701 1.77778 6.15264 2.15238 5.48584 2.81918C4.81905 3.48597 4.44444 4.39034 4.44444 5.33333V6.22222H11.5556ZM7.11111 11.5556V13.3333H8.88889V11.5556H7.11111ZM3.55556 11.5556V13.3333H5.33333V11.5556H3.55556ZM10.6667 11.5556V13.3333H12.4444V11.5556H10.6667Z"
                                  fill="#505050"
                                ></path>
                              </svg>
                            </span>
                            Change Password
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>
                          <NavLink to={"#"}>
                            <span className="sidebar-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.68424 0C1.97234 0 1.28959 0.284464 0.786196 0.790812C0.282803 1.29716 0 1.98392 0 2.7V15.3C0 16.0161 0.282803 16.7028 0.786196 17.2092C1.28959 17.7155 1.97234 18 2.68424 18H8.05272C8.76463 18 9.44737 17.7155 9.95077 17.2092C10.4542 16.7028 10.737 16.0161 10.737 15.3V2.7C10.737 1.98392 10.4542 1.29716 9.95077 0.790812C9.44737 0.284464 8.76463 0 8.05272 0H2.68424ZM11.8939 4.7637C12.0617 4.59498 12.2892 4.50019 12.5265 4.50019C12.7637 4.50019 12.9913 4.59498 13.159 4.7637L16.738 8.3637C16.9058 8.53247 17 8.76135 17 9C17 9.23865 16.9058 9.46752 16.738 9.6363L13.159 13.2363C12.9903 13.4002 12.7643 13.491 12.5297 13.4889C12.2951 13.4869 12.0707 13.3922 11.9048 13.2253C11.7389 13.0585 11.6448 12.8327 11.6427 12.5968C11.6407 12.3608 11.7309 12.1334 11.8939 11.9637L13.9455 9.9H6.26323C6.02593 9.9 5.79834 9.80518 5.63055 9.6364C5.46275 9.46761 5.36848 9.23869 5.36848 9C5.36848 8.7613 5.46275 8.53239 5.63055 8.3636C5.79834 8.19482 6.02593 8.1 6.26323 8.1H13.9455L11.8939 6.0363C11.7261 5.86752 11.6319 5.63865 11.6319 5.4C11.6319 5.16135 11.7261 4.93248 11.8939 4.7637Z"
                                  fill="#505050"
                                ></path>
                              </svg>
                            </span>
                            Logout
                          </NavLink>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <button className="login_button" onClick={openLoginModal}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 5.99988C11.2583 5.99988 10.5333 6.21981 9.91661 6.63187C9.29993 7.04392 8.81928 7.62959 8.53545 8.31482C8.25162 9.00004 8.17736 9.75404 8.32206 10.4815C8.46675 11.2089 8.8239 11.8771 9.34835 12.4015C9.8728 12.926 10.541 13.2831 11.2684 13.4278C11.9958 13.5725 12.7498 13.4983 13.4351 13.2144C14.1203 12.9306 14.706 12.45 15.118 11.8333C15.5301 11.2166 15.75 10.4916 15.75 9.74988C15.75 8.75532 15.3549 7.80149 14.6517 7.09823C13.9484 6.39497 12.9946 5.99988 12 5.99988ZM12 11.9999C11.555 11.9999 11.12 11.8679 10.75 11.6207C10.38 11.3735 10.0916 11.022 9.92127 10.6109C9.75098 10.1998 9.70642 9.74738 9.79324 9.31093C9.88005 8.87447 10.0943 8.47356 10.409 8.15889C10.7237 7.84422 11.1246 7.62993 11.561 7.54311C11.9975 7.45629 12.4499 7.50085 12.861 7.67115C13.2722 7.84145 13.6236 8.12983 13.8708 8.49985C14.118 8.86986 14.25 9.30487 14.25 9.74988C14.2493 10.3464 14.0121 10.9183 13.5902 11.3401C13.1684 11.7619 12.5965 11.9992 12 11.9999Z"
                          fill="white"
                        />
                        <path
                          d="M12 1.50012C9.9233 1.50012 7.89323 2.11594 6.16652 3.26969C4.4398 4.42345 3.09399 6.06332 2.29927 7.98195C1.50455 9.90057 1.29661 12.0118 1.70176 14.0486C2.1069 16.0854 3.10693 17.9563 4.57538 19.4247C6.04384 20.8932 7.91476 21.8932 9.95156 22.2984C11.9884 22.7035 14.0996 22.4956 16.0182 21.7009C17.9368 20.9061 19.5767 19.5603 20.7304 17.8336C21.8842 16.1069 22.5 14.0768 22.5 12.0001C22.4969 9.21631 21.3896 6.54742 19.4212 4.57897C17.4527 2.61051 14.7838 1.50326 12 1.50012ZM7.50001 19.7825V18.7501C7.50066 18.1536 7.73792 17.5817 8.15974 17.1599C8.58155 16.738 9.15347 16.5008 9.75001 16.5001H14.25C14.8465 16.5008 15.4185 16.738 15.8403 17.1599C16.2621 17.5817 16.4994 18.1536 16.5 18.7501V19.7825C15.1344 20.5799 13.5814 21.0001 12 21.0001C10.4186 21.0001 8.86563 20.5799 7.50001 19.7825ZM17.9944 18.6945C17.9795 17.7107 17.5786 16.7721 16.8782 16.081C16.1778 15.39 15.2339 15.0018 14.25 15.0001H9.75001C8.76608 15.0018 7.8222 15.39 7.1218 16.081C6.42139 16.7721 6.02054 17.7107 6.00563 18.6945C4.64555 17.48 3.68643 15.8811 3.25526 14.1095C2.82408 12.3378 2.9412 10.477 3.59111 8.77337C4.24101 7.06975 5.39304 5.60372 6.89466 4.5694C8.39627 3.53507 10.1766 2.98124 12 2.98124C13.8234 2.98124 15.6037 3.53507 17.1054 4.5694C18.607 5.60372 19.759 7.06975 20.4089 8.77337C21.0588 10.477 21.1759 12.3378 20.7448 14.1095C20.3136 15.8811 19.3545 17.48 17.9944 18.6945Z"
                          fill="white"
                        />
                      </svg>{" "}
                      Login
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M4.93689 3.9338C4.99934 3.86136 5.04298 3.7983 5.09606 3.74647L8.57172 0.269512C8.65845 0.172888 8.77119 0.103293 8.89644 0.0690726C9.0217 0.0348524 9.15407 0.0374817 9.27789 0.076581C9.40122 0.10803 9.51308 0.17424 9.59987 0.26734C9.68666 0.36044 9.74484 0.476477 9.76757 0.601711C9.79514 0.71992 9.7907 0.84337 9.75446 0.959217C9.71822 1.07506 9.65147 1.17912 9.56145 1.26054C9.11892 1.70224 8.67699 2.14414 8.23571 2.58625C7.31984 3.50212 6.405 4.41672 5.49122 5.33009C5.42418 5.42038 5.33708 5.49372 5.23662 5.54425C5.13616 5.59478 5.02526 5.62109 4.9128 5.62109C4.80035 5.62109 4.68945 5.59478 4.58899 5.54425C4.48853 5.49372 4.40113 5.42038 4.33409 5.33009C2.97361 3.97003 1.61374 2.60955 0.254096 1.24865C0.179546 1.18648 0.118627 1.1096 0.0751145 1.02283C0.031602 0.936056 0.00625349 0.841263 0.00102155 0.744332C-0.00421038 0.647402 0.010704 0.550423 0.0446236 0.459471C0.0785432 0.368518 0.130873 0.28553 0.198298 0.215696C0.265723 0.145862 0.346726 0.0906895 0.436432 0.0535985C0.526138 0.0165074 0.622638 -0.00170155 0.719693 0.000124998C0.816747 0.00195155 0.912267 0.0237921 1.00051 0.0642322C1.08876 0.104672 1.1678 0.16285 1.23255 0.235172C1.77999 0.780529 2.32619 1.32712 2.87113 1.87497L4.79938 3.80707C4.83497 3.84079 4.87445 3.8726 4.93689 3.9338Z"
                          fill="white"
                        />
                      </svg>{" "}
                    </button>
                  )}
                </li>
              </ul>
              <ul className="menu_openbutton">
                <li>
                  {" "}
                  <button onClick={toggleDrawer}>
                    <img src={images["menu.png"]} alt="" />
                  </button>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="mobile_menu"
        >
          {/* <img src={images["logo.png"]} alt="Logo" className="drawer_logo"/> */}
          <div className="drawer_mobilemenu">
            <button className="close_menubtn" onClick={toggleDrawer}>
              <img src={images["close_btn.png"]} alt="" />
            </button>
            <div className="navbar-nav drawer_menulinks">
              <NavLink className="nav-item nav-link " to="/">
                Home
              </NavLink>
              <NavLink className="nav-item nav-link" to="/contact-us">
                Contact Us
              </NavLink>
              <NavLink className="nav-item nav-link" to="/buy-passes">
                Buy Passes
              </NavLink>
              <NavLink className="nav-item nav-link" to="/promotion">
                Promotion
              </NavLink>
              <NavLink className="nav-item nav-link" to="/qct-history">
                QCT History
              </NavLink>
              <NavLink className="nav-item nav-link" to="/track-enhancements">
                Track Enhancements
              </NavLink>

              {isAuthenticated ? (
                <NavLink
                  to="/dashboard"
                  className="mobilelogin_button dashbaord_button"
                >
                  Dashboard
                </NavLink>
              ) : (
                <button className="mobilelogin_button" onClick={openLoginModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 5.99988C11.2583 5.99988 10.5333 6.21981 9.91661 6.63187C9.29993 7.04392 8.81928 7.62959 8.53545 8.31482C8.25162 9.00004 8.17736 9.75404 8.32206 10.4815C8.46675 11.2089 8.8239 11.8771 9.34835 12.4015C9.8728 12.926 10.541 13.2831 11.2684 13.4278C11.9958 13.5725 12.7498 13.4983 13.4351 13.2144C14.1203 12.9306 14.706 12.45 15.118 11.8333C15.5301 11.2166 15.75 10.4916 15.75 9.74988C15.75 8.75532 15.3549 7.80149 14.6517 7.09823C13.9484 6.39497 12.9946 5.99988 12 5.99988ZM12 11.9999C11.555 11.9999 11.12 11.8679 10.75 11.6207C10.38 11.3735 10.0916 11.022 9.92127 10.6109C9.75098 10.1998 9.70642 9.74738 9.79324 9.31093C9.88005 8.87447 10.0943 8.47356 10.409 8.15889C10.7237 7.84422 11.1246 7.62993 11.561 7.54311C11.9975 7.45629 12.4499 7.50085 12.861 7.67115C13.2722 7.84145 13.6236 8.12983 13.8708 8.49985C14.118 8.86986 14.25 9.30487 14.25 9.74988C14.2493 10.3464 14.0121 10.9183 13.5902 11.3401C13.1684 11.7619 12.5965 11.9992 12 11.9999Z"
                      fill="white"
                    />
                    <path
                      d="M12 1.50012C9.9233 1.50012 7.89323 2.11594 6.16652 3.26969C4.4398 4.42345 3.09399 6.06332 2.29927 7.98195C1.50455 9.90057 1.29661 12.0118 1.70176 14.0486C2.1069 16.0854 3.10693 17.9563 4.57538 19.4247C6.04384 20.8932 7.91476 21.8932 9.95156 22.2984C11.9884 22.7035 14.0996 22.4956 16.0182 21.7009C17.9368 20.9061 19.5767 19.5603 20.7304 17.8336C21.8842 16.1069 22.5 14.0768 22.5 12.0001C22.4969 9.21631 21.3896 6.54742 19.4212 4.57897C17.4527 2.61051 14.7838 1.50326 12 1.50012ZM7.50001 19.7825V18.7501C7.50066 18.1536 7.73792 17.5817 8.15974 17.1599C8.58155 16.738 9.15347 16.5008 9.75001 16.5001H14.25C14.8465 16.5008 15.4185 16.738 15.8403 17.1599C16.2621 17.5817 16.4994 18.1536 16.5 18.7501V19.7825C15.1344 20.5799 13.5814 21.0001 12 21.0001C10.4186 21.0001 8.86563 20.5799 7.50001 19.7825ZM17.9944 18.6945C17.9795 17.7107 17.5786 16.7721 16.8782 16.081C16.1778 15.39 15.2339 15.0018 14.25 15.0001H9.75001C8.76608 15.0018 7.8222 15.39 7.1218 16.081C6.42139 16.7721 6.02054 17.7107 6.00563 18.6945C4.64555 17.48 3.68643 15.8811 3.25526 14.1095C2.82408 12.3378 2.9412 10.477 3.59111 8.77337C4.24101 7.06975 5.39304 5.60372 6.89466 4.5694C8.39627 3.53507 10.1766 2.98124 12 2.98124C13.8234 2.98124 15.6037 3.53507 17.1054 4.5694C18.607 5.60372 19.759 7.06975 20.4089 8.77337C21.0588 10.477 21.1759 12.3378 20.7448 14.1095C20.3136 15.8811 19.3545 17.48 17.9944 18.6945Z"
                      fill="white"
                    />
                  </svg>{" "}
                  Login
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                  >
                    <path
                      d="M4.93689 3.9338C4.99934 3.86136 5.04298 3.7983 5.09606 3.74647L8.57172 0.269512C8.65845 0.172888 8.77119 0.103293 8.89644 0.0690726C9.0217 0.0348524 9.15407 0.0374817 9.27789 0.076581C9.40122 0.10803 9.51308 0.17424 9.59987 0.26734C9.68666 0.36044 9.74484 0.476477 9.76757 0.601711C9.79514 0.71992 9.7907 0.84337 9.75446 0.959217C9.71822 1.07506 9.65147 1.17912 9.56145 1.26054C9.11892 1.70224 8.67699 2.14414 8.23571 2.58625C7.31984 3.50212 6.405 4.41672 5.49122 5.33009C5.42418 5.42038 5.33708 5.49372 5.23662 5.54425C5.13616 5.59478 5.02526 5.62109 4.9128 5.62109C4.80035 5.62109 4.68945 5.59478 4.58899 5.54425C4.48853 5.49372 4.40113 5.42038 4.33409 5.33009C2.97361 3.97003 1.61374 2.60955 0.254096 1.24865C0.179546 1.18648 0.118627 1.1096 0.0751145 1.02283C0.031602 0.936056 0.00625349 0.841263 0.00102155 0.744332C-0.00421038 0.647402 0.010704 0.550423 0.0446236 0.459471C0.0785432 0.368518 0.130873 0.28553 0.198298 0.215696C0.265723 0.145862 0.346726 0.0906895 0.436432 0.0535985C0.526138 0.0165074 0.622638 -0.00170155 0.719693 0.000124998C0.816747 0.00195155 0.912267 0.0237921 1.00051 0.0642322C1.08876 0.104672 1.1678 0.16285 1.23255 0.235172C1.77999 0.780529 2.32619 1.32712 2.87113 1.87497L4.79938 3.80707C4.83497 3.84079 4.87445 3.8726 4.93689 3.9338Z"
                      fill="white"
                    />
                  </svg>{" "}
                </button>
              )}
            </div>
          </div>
        </Drawer>
      </div>

      <LoginModal loginmodal={loginmodal} closeLoginModal={closeLoginModal} />
    </>
  );
};

export default Header;
